import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./page/Home";
import Block from "./components/Audit";
import Token from "./components/WallOfShame";
import Transaction from "./components/KYC"
import Validator from "./components/NewsRoom"
import AuditProject from "./components/AuditProject";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import BlockDetails from "./components/BlockDetailsPage";
import ValidatorDetails from "./components/ValidatorDetailsPage";
import TransactionDetails from "./components/TransactionDetailsPage"
import TokenDetails from "./components/TokenDetailsPage"
import AddressDetails from "./components/AddressDetailsPage"
import WallOfShameProject from "./components/WallOfShameProject";
import KYCProject from "./components/KYCProject";
import NewsDetail from "./components/NewsDetails"
import RequestQuote from "./page/Request"




const App: React.FC = () => {
  return (
    <>
      <Navigation />
      <div className="layout-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/audit" element={<Block />} />
          <Route path="/wallofshame" element={<Token />} />
                

          <Route path="/kyc" element={<Transaction />} />
                    <Route path="/auditproject" element={<AuditProject />} />
                    <Route path="/wallofshameproject" element={<WallOfShameProject />} />
                    <Route path="/kycproject" element={<KYCProject />} />
                    <Route path="/newsdetail" element={<NewsDetail />} />
                    <Route path="/request" element={<RequestQuote />} />
          <Route path="/newsroom" element={<Validator />} />
         

        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
