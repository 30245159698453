import React, { useState } from 'react';
import Maps from '../TransactionHome';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import Charts from "../PairExplorerDetails/validator2";

import { ReactComponent as Process } from '../../assets/images/process.svg';
import { ReactComponent as Eth } from '../../assets/images/eth.svg';
import { ReactComponent as Bnb } from '../../assets/images/bnb.svg';
import { ReactComponent as Al } from '../../assets/images/al.svg';
import { ReactComponent as Poly } from '../../assets/images/polygon.svg';
import { ReactComponent as Sol } from '../../assets/images/solana.svg';
import { ReactComponent as Tez } from '../../assets/images/tezos.svg';
import { ReactComponent as Hy } from '../../assets/images/hyperledger.svg';
import { ReactComponent as Fan } from '../../assets/images/fantom.svg';
import Analysis from "../../assets/images/analysis.png"
import Analysismob from "../../assets/images/analysismob.png"




const MainWrapper = styled.div`
margin: 0px 18px;
max-width:100%;
border-radius:20px;
padding:20px;
background-color:black;
grid-template-columns:20% 80%;


p{
  text-align:center;
  font-size:17px;
  color:#ffba00;
}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
}


`;


const Audits = styled.div`
width:100%;
margin-top:30px;

img{
  width:100%;
}

@media (max-width: 768px) {
display:none; 
 }

`;

const Auditsmob = styled.div`
width:100%;
display:none;
margin-top:30px;

img{
  width:100%;
}

@media (max-width: 768px) {
display:block; 
 }

`;


const AuditDetails = styled.div`
margin:40px 80px;
padding:30px;
border-radius:20px;
background-color:black;

p{
  font-size:14px;
}

`;


const Icon = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:20px;
margin-top:20px;

svg{
  width:50px;
  height:50px;
}

`;


const BlockThree: React.FC = () => {
  
  return (
    <div className="blockthree-wrapper">
       <MainWrapper>
<p>THE SECURITY</p>
<h1>How We Secure Your Web3 Project
</h1>
<Audits>

<img src={Analysis}/></Audits>


<Auditsmob>

<img src={Analysismob}/></Auditsmob>

       </MainWrapper>
    </div>
  );
};

export default BlockThree;
