import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';

// Styled Components
const Section = styled.section`
  padding: 20px 0;
  
`;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const SearchContainer = styled.div`
  margin: 20px 0;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
 
`;
const TableRow = styled.tr`
`;

const TableData = styled.td`
  padding: 10px;
  width:fit-content;
    border-bottom: 1px solid var(--background);

`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Button = styled.button`
  background-color: rgba(255, 244, 140, 0.25);
  color: #ffba00;
  border:1px solid #ffba00;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageNumber = styled.div`
  font-size: 14px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
`;


const Header = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  margin-bottom:20px;

  @media (max-width: 768px) {
    grid-template-columns:1fr;

  }

`;


const MainTable = styled.div`
  width: 90%; /* Adjust the width for mobile screens */
  background: black;
  padding: 20px;
  border-radius: 20px;
 


  .tablescroll{
    overflow-x: auto;
   
    &::-webkit-scrollbar {
      height: 2px; /* Adjust the scrollbar width as needed */
    }

    &::-webkit-scrollbar-track {
      background: #000; /* Track color */
    }
    
    /* Scrollbar Handle */
    &::-webkit-scrollbar-thumb {
      background: #a37800; /* Handle color */
    }
    
    /* Scrollbar Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a37800; /* Handle color on hover */
    }
    
  
    // Add mobile styles here
    @media (max-width: 768px) {
      // Adjust styles for smaller screens
      width: 100%;
    }

  }
`;



const TableHead = styled.thead`
  background-color: var(--background);
  padding:5px;
  height:55px;
  border:none;

  th{
    padding: 10px;
    text-align: left;
    font-weight: bold;
  }

  tr{
    padding:5px;
  }
`;



// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDARUzWnCkGlBFA4xrxY7K7ELo6isfJzUg",
  authDomain: "proassure-f7e82.firebaseapp.com",
  databaseURL: "https://proassure-f7e82-default-rtdb.firebaseio.com",
  projectId: "proassure-f7e82",
  storageBucket: "proassure-f7e82.appspot.com",
  messagingSenderId: "234072300680",
  appId: "1:234072300680:web:937cf000c5fb6bb460533d",
  measurementId: "G-2Z4R57225G"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, 'wallofshame');

const ITEMS_PER_PAGE = 10;

const AuditedProjects: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userData, setUserData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true); // Add this line for loading state


  useEffect(() => {
    onValue(userDataRef, (snapshot) => {
      const data = snapshot.val();
      const filteredData = data ? Object.values(data) : [];

      if (searchTerm) {
        const searchTermLC = searchTerm.toLowerCase();
        const searchResults = filteredData.filter((user: any) =>
          user.name.toLowerCase().includes(searchTermLC)
        );
        setUserData(searchResults.reverse());
      } else {
        setUserData(filteredData.reverse());
      }
            setLoading(false); // Data loading is complete

    });
  }, [searchTerm]);

  const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
  const lastItemIndex = currentPage * ITEMS_PER_PAGE;
  const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
  const displayedUserData = userData.slice(firstItemIndex, lastItemIndex);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

   const noProjectsMessage = (
    <TableRow>
      <TableData colSpan={4} style={{ textAlign: 'center',height:'100px' }}>
        No Project Found
      </TableData>
    </TableRow>
  );
  const createTableRow = (user: any) => (
    <TableRow key={user.userId}>
      <TableData>
        <ProfileImage src={user.logoPicture} alt="Logo Picture" />
      </TableData>
      <TableData style={{minWidth:'300px'}}>{user.name}</TableData>
      <TableData>{user.status}</TableData>
      <TableData style={{minWidth:'150px'}}>
        <Link to={`/wallofshameproject?name=${encodeURIComponent(user.userId)}`}>
        <Button>View Report</Button>
      </Link>
      </TableData>
    </TableRow>
  );

  const redirectToReport = (userId: string) => {
    const encodedName = encodeURIComponent(userId);
    window.location.href = `wallofshameproject.html?name=${encodedName}`;
  };

  return (
    <Section>
      <Container>
        <Header>
        <header>
        <h2 style={{display:'flex',alignItems:'center',fontWeight:500,color:'#ffba00'}}>8Bit Wall of Shame</h2>
        </header>
        <div className={`searbar flex-item ${isFocused ? 'focus-within' : ''}`}>
           
           <input
             type="search"
             id="search-box"
             placeholder="Search by Project Name"
             value={searchTerm}
             onChange={handleSearchChange}
                          onFocus={handleFocus}
             onBlur={handleBlur}
           />
            <div className="searchBar-icon">
           <SearchIcon />

           </div>
         </div>
        </Header>
        
      </Container>
      <div style={{display:'flex',justifyContent:'center'}}>
<MainTable>
      {loading ? ( // Conditional rendering based on loading state
      <LoaderContainer>
        <p>Loading...</p>
      </LoaderContainer>
    ) : (
      <div className="tablescroll">
      <Table>
        <TableHead>
          <tr>
            <th  style={{borderRadius:'10px 0 0 10px'}}><span  style={{marginLeft:'5px'}}>Logo</span></th>
            <th style={{minWidth:'fit-content'}}>Project Name</th>
            <th>Status</th>
            <th style={{borderRadius:'0 10px 10px 0'}}>Actions</th>
          </tr>
        </TableHead>
        <tbody>
              {displayedUserData.length === 0 ? (
                noProjectsMessage
              ) : (
                displayedUserData.map((user) => createTableRow(user))
              )}
            </tbody>
      </Table>
      </div>
      )}
      <PaginationContainer>
        <PaginationButtons>
          <Button onClick={handlePreviousPage}>Previous</Button>
          
          <Button onClick={handleNextPage}>Next</Button>
        </PaginationButtons>
        <PageNumber>Page {currentPage} of {totalPages}</PageNumber>
      </PaginationContainer>
      </MainTable>
      </div>
    </Section>
  );
};

export default AuditedProjects;
