import React, { useState, ChangeEvent, FormEvent } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as FileTextIcon } from '../../assets/icons/file-text.svg';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref as databaseRef, push, set as setDatabaseData, DataSnapshot } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import "../NewsDetails/NewsDetails.scss"

declare module 'firebase/storage' {
  interface StorageReference {
    fullPath: string;
  }
}

declare module 'firebase/app' {
  interface FirebaseApp {
    storage: (app?: FirebaseApp) => FirebaseStorage;
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyDARUzWnCkGlBFA4xrxY7K7ELo6isfJzUg",
  authDomain: "proassure-f7e82.firebaseapp.com",
  databaseURL: "https://proassure-f7e82-default-rtdb.firebaseio.com",
  projectId: "proassure-f7e82",
  storageBucket: "proassure-f7e82.appspot.com",
  messagingSenderId: "234072300680",
  appId: "1:234072300680:web:937cf000c5fb6bb460533d",
  measurementId: "G-2Z4R57225G"
};

const firebaseApp = initializeApp(firebaseConfig);

interface ScamPostFormProps {
  onClose: () => void;
}

const options = [
  { value: 'Audit', label: 'Audit' },
  { value: 'KYC', label: 'KYC' },
  { value: 'Both', label: 'Both' },
];

const ScamPostForm: React.FC<ScamPostFormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    telegram: '',
    sc: '',
    projectName: '',
    title: '', // Add title field

    description: '',
  });

  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [evidenceFile, setEvidenceFile] = useState<File | null>(null);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [wordCount, setWordCount] = useState<number>(0); // Track word count
  const [titleWordCount, setTitleWordCount] = useState<number>(0);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    const words = inputValue.split(/\s+/).filter((word) => word !== '');

    // Set a maximum word count (e.g., 1000 words)
    const maxWordCount = 1000;

    // Update word count
    setWordCount(words.length);

    // If the word count exceeds the maximum, truncate the input
    if (words.length > maxWordCount) {
      const truncatedWords = words.slice(0, maxWordCount);
      const truncatedValue = truncatedWords.join(' ');
      setFormData({ ...formData, description: truncatedValue });
    } else {
      setFormData({ ...formData, description: inputValue });
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    const words = inputValue.split(/\s+/).filter((word) => word !== '');

    // Set a maximum word count for the title (e.g., 30 words)
    const maxWordCount = 30;

    // Update word count
    setTitleWordCount(words.length);

    // If the word count exceeds the maximum, truncate the input
    if (words.length > maxWordCount) {
      const truncatedWords = words.slice(0, maxWordCount);
      const truncatedValue = truncatedWords.join(' ');
      setFormData({ ...formData, title: truncatedValue });
    } else {
      setFormData({ ...formData, title: inputValue });
    }
  };

  const handleLogoFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    setLogoFile(selectedFile || null);
  };

  const handleEvidenceFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    setEvidenceFile(selectedFile || null);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const uploadFileToStorage = async (
    storage: FirebaseStorage,
    folderName: string,
    file: File | null
  ) => {
    if (!file) return null;

    const fileRef = storageRef(storage, `${folderName}/${file.name}`);

    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Firebase setup
    const storage = getStorage(firebaseApp) as FirebaseStorage;

    try {
      // Upload logo and evidence images to Firebase Storage
      const [logoUrl, evidenceUrl] = await Promise.all([
        uploadFileToStorage(storage, 'logo-pictures', logoFile),
        uploadFileToStorage(storage, 'evidence-pictures', evidenceFile),
      ]);

      // Include image URLs in form data
      const formDataWithImages = {
        ...formData,
        logoUrl,
        evidenceUrl,
      };

      // Assuming you have an API endpoint for form submission
      const response = await fetch('https://formspree.io/f/xjvqadky', {
        method: 'POST',
        body: JSON.stringify(formDataWithImages),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setStatusMessage('Scam Report Submitted Successfully!');
        setTimeout(() => onClose(), 3000);

        // Clear the form and file inputs after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          telegram: '',
          sc: '',
          projectName: '',
          title: '', // Clear title field as well
          description: '',
        });
        setTitleWordCount(0); // Reset title word count
        setWordCount(0); // Reset description word count
        setLogoFile(null);
        setEvidenceFile(null)
      } else {
        setStatusMessage('Failed to submit the scam report. Please try again later.');
        setTimeout(() => setStatusMessage(null), 3000);
      }
    } catch (error) {
      setStatusMessage('An error occurred while submitting the scam report.');
      setTimeout(() => setStatusMessage(null), 3000);
      console.error(error);
    }
  };

  return (
    <div className="custom-scrollbar" style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'black',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0px 0px 10px rgba(256,187,0,0.5)',
      zIndex: '1000',
      maxWidth: '90%',
      maxHeight: '90%',
      overflowY: 'auto'
    }}>
      <ToastContainer />
           <p style={{textAlign:'center',fontSize:22,color:'#ffba00',marginBottom:20}} >Report Scam</p>
      <div className="formo">
        <form onSubmit={handleSubmit} className="requestform">
          <div className="formo">
            <div className="formgrid">
              <label>
                <input
                  type="text"
                  className="inputrequest"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                <input
                  type="text"
                  className="inputrequest"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  
                />
              </label>
            </div>
            <div className="formgrid">
              <label>
                <input
                  type="email"
                  name="email"
                  className="inputrequest"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                <input
                  type="text"
                  name="telegram"
                  className="inputrequest"
                  placeholder="Telegram ID"
                  value={formData.telegram}
                  onChange={handleInputChange}
                  required
                  style={{ marginRight: '40px' }}
                />
              </label>
            </div>
              <label>
                <input
                  type="text"
                  name="projectName"
                  placeholder="Project Name"
                  className="inputrequest"
                  value={formData.projectName}
                  onChange={handleInputChange}
                  required
                />
              </label>
            
            <label>
              <input
                type="text"
                name="sc"
                className="inputrequest"
                placeholder="Smart Contract"
                value={formData.sc}
                onChange={handleInputChange}
                style={{ marginRight: '40px' }}
              />
            </label>
            <label>
  <textarea
    name="title" // Change name to "title"
    required
    onInput={handleTitleChange} // Use handleTitleChange for title
    placeholder="Title (Max 30 words)"
    className="inputrequest descrequest"
    value={formData.title} // Use formData.title for the value
    onChange={handleTitleChange} // Use handleTitleChange for onChange
  ></textarea>
</label>
            <label>
              <textarea
                name="description"
                required
                onInput={handleDescriptionChange}
                placeholder="Description (Max 1000 words)"
                className="inputrequest descrequest"
                value={formData.description}
                onChange={handleDescriptionChange}
              ></textarea>
              <div className="word-count">{wordCount} words</div>
            </label>
            <div className="choose-input-file">
              <label htmlFor="choose-logo-file">
                <input
                  type="file"
                  required
                  placeholder="Logo File"
                  accept=".jpg,.jpeg,.png,.svg,.gif"
                  id="choose-logo-file"
                  onChange={handleLogoFileChange}
                />
                <div className="flex-item">
                  <FileTextIcon />
                  <p>{logoFile ? logoFile.name : "Choose Logo"}</p>
                </div>
              </label>
            </div>
            <div className="choose-input-file">
              <label htmlFor="choose-evidence-file">
                <input
                  type="file"
                  required
                  placeholder="Evidence File"
                  accept=".jpg,.jpeg,.png,.svg,.gif"
                  id="choose-evidence-file"
                  onChange={handleEvidenceFileChange}
                />
                <div className="flex-item">
                  <FileTextIcon />
                  <p>{evidenceFile ? evidenceFile.name : "Choose Evidence Image"}</p>
                </div>
              </label>
            </div>
          </div>
          <div className='sscamrequestdiv'>
            <div>
              <button className="custom-button-request cancel" onClick={onClose} >
                Cancel
              </button>
            </div>
            <div>
              <button type="submit" className="custom-button-request">
                Submit Scam Report
              </button>
            </div>
          </div>
        </form>
        {statusMessage && <div className={`status-message ${statusMessage.includes('Successfully') ? 'success' : 'error'}`}>{statusMessage}</div>}
      </div>
    </div>
  );
};

export default ScamPostForm;
