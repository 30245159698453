import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { EthereumClient } from "@web3modal/ethereum";
import "./index.scss";
import App from "./App";
import { chains, projectId, wagmiConfig } from "./utils/Connectors/Connectors";
import { WagmiConfig } from "wagmi";
import { Web3Modal } from "@web3modal/react";

const ethereumClient = new EthereumClient(wagmiConfig, chains);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
     
    </BrowserRouter>
  </React.StrictMode>
);
