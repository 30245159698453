import React, { useState } from 'react';
import Maps from '../TransactionHome';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import Charts from "../PairExplorerDetails/validator2";

import { ReactComponent as Audit } from '../../assets/images/audit.svg';
import { ReactComponent as Eth } from '../../assets/images/eth.svg';
import { ReactComponent as Bnb } from '../../assets/images/bnb.svg';
import { ReactComponent as Al } from '../../assets/images/al.svg';
import { ReactComponent as Poly } from '../../assets/images/polygon.svg';
import { ReactComponent as Sol } from '../../assets/images/solana.svg';
import { ReactComponent as Tez } from '../../assets/images/tezos.svg';
import { ReactComponent as Hy } from '../../assets/images/hyperledger.svg';
import { ReactComponent as Fan } from '../../assets/images/fantom.svg';
import Logo from '../../assets/images/LogoImg.png'




const MainWrapper = styled.div`
margin: 50px 0px;
max-width:100%;
border-radius:15px;
padding:15px;
grid-template-columns:15% 80%;


p{
  text-align:center;
  font-size:15px;
  color:#ffba00;
}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
  @media (max-width: 768px) {
   font-size:22px;
  
  }
}


`;


const Audits = styled.div`
display:grid;
margin-top:15px;
width:100%;
grid-template-columns:1fr 1fr 1fr;
column-gap:15px;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  row-gap:20px;

}

.auditkyc{
  background-color:black;
  border-radius:15px;
  padding:35px 15px;
}


svg{
  width:160px;
  height:160px;
}

`;


const Auditsnew = styled.div`
display:grid;
margin-top:15px;
width:100%;
grid-template-columns:1fr 1fr;
column-gap:15px;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  row-gap:20px;

}

.auditkyc{
  background-color:black;
  border-radius:15px;
  padding:35px 18px;
}


svg{
  width:160px;
  height:160px;
}

`;



const AuditDetails = styled.div`
padding:30px;
width:100%;
margin-top:15px;
border-radius:15px;
background-color:black;

p{
  font-size:14px;
}

`;


const Icon = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:15px;
margin-top:15px;

svg{
  width:50px;
  height:50px;
}

img{
  width:50px;
  height:50px;
}
`;


const BlockThree: React.FC = () => {
  
  return (
    <div className="blockthree-wrapper">
       <MainWrapper>
<p>WHY ARE WE HERE ?

</p>
<h1>What is the Need of Smart Contract Audit ? </h1>
<Auditsnew>

<div className='auditkyc'>
<h1 style={{textAlign:'center',fontSize:20,color:'#fff'}}>Enhanced Reputation</h1>
  <h1 style={{textAlign:'center',fontSize:15,color:'#ffba00'}}>By conducting audits and ensuring the security and reliability of smart contracts, businesses and projects can enhance their reputation and credibility in the blockchain and crypto space.</h1>
</div>

<div className='auditkyc'>
<h1 style={{textAlign:'center',fontSize:20,color:'#fff'}}>Legal Protection</h1>
  <h1 style={{textAlign:'center',fontSize:15,color:'#ffba00'}}>In case of disputes or legal issues, having a professionally audited smart contract can provide evidence that due diligence was performed to secure the contract's integrity.</h1>
</div>
</Auditsnew>
<Audits>
<div className='auditkyc'>
<h1 style={{textAlign:'center',fontSize:20,color:'#fff'}}>Security Assurance</h1>
  <h1 style={{textAlign:'center',fontSize:15,color:'#ffba00'}}>Smart contracts are immutable, and require pre-deployment security audits to prevent vulnerabilities.</h1>
</div>

<div className='auditkyc'>
<h1 style={{textAlign:'center',fontSize:20,color:'#fff'}}>Risk Mitigation</h1>
  <h1 style={{textAlign:'center',fontSize:15,color:'#ffba00'}}>Audits crucial for identifying, mitigating risks, especially in finance where errors cost significantly.</h1>
</div>

<div className='auditkyc'>
<h1 style={{textAlign:'center',fontSize:20,color:'#fff'}}>Compliance</h1>
  <h1 style={{textAlign:'center',fontSize:15,color:'#ffba00'}}>In regulated industries, smart contracts must adhere to specific legal and compliance requirements. </h1>
</div>
</Audits>
<Auditsnew>

<div className='auditkyc'>
<h1 style={{textAlign:'center',fontSize:20,color:'#fff'}}>Trust Building</h1>
  <h1 style={{textAlign:'center',fontSize:15,color:'#ffba00'}}>Audited smart contracts instill trust among users, investors, and stakeholders. They demonstrate a commitment to security and transparency, which can be a competitive advantage.</h1>
</div>

<div className='auditkyc'>
<h1 style={{textAlign:'center',fontSize:20,color:'#fff'}}>Cost Savings</h1>
  <h1 style={{textAlign:'center',fontSize:15,color:'#ffba00'}}>Identifying and fixing issues in smart contracts before deployment is more cost-effective than dealing with the consequences of a security breach or contract failure.</h1>
</div>
</Auditsnew>




       </MainWrapper>
    </div>
  );
};

export default BlockThree;
