import React, { useState,useEffect } from 'react';
import "./RequestQuote.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const options = [
  { value: 'Audit', label: 'Audit' },
  { value: 'KYC', label: 'KYC' },
  { value: 'Both', label: 'Both' },
];

const MyComponent: React.FC = () => {
  const [wordCount, setWordCount] = useState(0);

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    // Update the formData when selectedOption changes
    setFormData({ ...formData, services: selectedOption || '' });
  }, [selectedOption]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    telegram: '',
    projectName: '',
    services: selectedOption || '', // Use selectedOption, not selectedValue
    description: '',
  });

  const [statusMessage, setStatusMessage] = useState('');

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = event.target.value;
    setFormData({ ...formData, description });
    const words = description.split(/\s+/).filter(Boolean);
    setWordCount(words.length);
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validate form fields (you can add your validation logic here)

    // Assuming you have an API endpoint for form submission
    try {
      const response = await fetch('https://formspree.io/f/xleypkdw', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        toast.success('Quote Request Submitted Successfully! We will contact you soon...', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-dark', // Apply the custom dark theme

        });
  
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          telegram: '',
          projectName: '',
          services: selectedOption || '', // Use selectedOption, not selectedValue
          description: '',
        });
      } else {
        toast.error('Failed to submit the form. Please try again later.', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-dark', // Apply the custom dark theme

        });
      }
    } catch (error) {
      toast.error('An error occurred while submitting the form.', {
        position: toast.POSITION.TOP_RIGHT,
        className: 'toast-dark', // Apply the custom dark theme

      });
      console.error(error);
    }
  };

  const redirectToTelegram = () => {
    const telegramLink = "https://t.me/lovep_8bit";
    window.location.href = telegramLink;
  };

  const sendEmail = () => {
    const emailAddress = "admin@8bitchain.io";
    const mailtoLink = `mailto:${encodeURIComponent(emailAddress)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div>
         <ToastContainer />

            <div className="formo">
              <form onSubmit={handleSubmit} className="requestform">
                <div className="formo">
                  <div className="formgrid">
                  <label>
                    <input
                      type="text"
                      className="inputrequest inputreques"
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      className="inputrequest inputreques"
                      placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  </div>
                  <div className="formgrid">

                  <label>
                    <input
                      type="email"
                      name="email"
                                            className="inputrequest inputreques"

                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      name="telegram"
                      className="inputrequest inputreques"

                      placeholder="Telegram ID"
                      value={formData.telegram}
                      onChange={handleInputChange}
                      required
                      style={{ marginRight: '40px' }}
                    />
                  </label>
                  </div>
                  <div className="formgrid">

                  <label>
                    <input
                      type="text"
                      name="projectName"
                      placeholder="Project Name"
                      className="inputrequest inputreques"

                      value={formData.projectName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                  <select
            value={selectedOption || ''}
            onChange={handleChange}
            className="selectiondropdownrequest selectiondropdownreques"
          >
            <option value="" disabled className="optionss">
              Select Services
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value} className="optionss">
                {option.label}
              </option>
            ))}
          </select>
                  </div>
                  <label>
                    <textarea
                      name="description"
                      required
                      maxLength={1000}
                      onInput={handleDescriptionChange}
                      placeholder="Description"
                      className="inputrequest inputreques descrequest"
                      value={formData.description}
                      onChange={handleInputChange}
                    ></textarea>
                  </label>
                </div>
                <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
                <button type="submit" className="custom-button-request custom-button-reques">
                  Request Quote
                </button>
                </div>
              </form>

              {/* Status message */}

              {/* "Or" section */}
              
             
            </div>
       
 
    </div>
  );
};

export default MyComponent;
