import React, { useState } from "react";
import styled from "styled-components";
import Hero from "../../components/Hero";
import BlockTwo from '../../components/BlockTwo';
import BlockThree from "../../components/BlockThree";
import BlockFour from "../../components/BlockFour";
import BlockRow from "../../components/BlockRow";
import TransactionRow from "../../components/TransactionRow";
import AuditHome from "../../components/TransactionDetailsPage";


const Home: React.FC = () => {
 
  return (
    <div>
      <div className="pad">
        <Hero />
        <BlockThree />
        <TransactionRow />
<AuditHome />
        <BlockFour />
      </div>
      </div>
  );
};

export default Home;
