import React, { useState } from 'react';
import './Hero.scss';
import Map from '../Map';
import ReactDOMServer from 'react-dom/server';
import { CircularProgressbar,CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Markquee from '../MarkQuee';
import ToggleBtn from '../ToggleBtn';
import { ReactComponent as Shield } from '../../assets/images/Shield.svg';
import styled from 'styled-components';
import Front from "../../assets/images/front.png"

const percentage = 66;
const percentage2 = 78;
const trailWidth = 2; // Desired trail width in pixels

const Main = styled.div`
display:grid;
height:100%;
grid-template-columns:  60% 40%;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  row-gap:20px;

}
.shield{
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;

  img{
    width:350px;
    height:350px;
    @media (max-width: 768px) {
      width:250px;
      height:250px;
    
    }
  }
}


.texture{
  height:100%;
  display:flex;
  padding-left:40px;
  justify-content:center;
  align-items:center;
  @media (max-width: 768px) {
    padding-left:10px;

  
  }


  h1{
    font-size:55px;
    font-weight:400;
    color:#ffba00;
     @media (max-width: 768px) {
      font-size:35px;

  
  }
  }

  p{
    font-size:20px;
    font-weight:400;
    color:#fff;
    margin-top:30px;
    margin-bottom:30px;
  }
}


`;



const Buttons = styled.div`
width:80%;
display:grid;
grid-template-columns:1fr 1fr;
column-gap:20px;
font-size:55px;


a{
  width:100%;
  padding-top:9px;
  padding-bottom:9px;
  background-color:transparent;
  color:#ffba00;
  font-size:18px;

  border:1px solid #ffba00;
  border-radius:5px;
  text-align:center;
}
@media (max-width: 768px) {
  grid-template-columns:1fr;
  a{
    width:100%;
    padding-top:9px;
    padding-bottom:9px;
    background-color:transparent;
    color:#ffba00;
    font-size:18px;
    border:1px solid #ffba00;
    border-radius:5px;
    text-align:center;
  }

}
`;








const Hero: React.FC = () => {
  const progress = 75; // Example progress value

  const [checked, setChecked] = React.useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="hero-wrapper">
      <div className="mx">
      <div className="box-wrapper"> {/* Added 'waves-background' class */}
          <Main>
            <div className="texture"><div><h1>Your Shield in Cyberspace</h1>
            <p>Elevating Trust with Smart Contract Audits and KYC Excellence

</p>
<Buttons>
<a href="./request">Request Free Quote</a>

</Buttons>
</div>
            </div>
            <div className="shield"><img src={Front} /></div>
          </Main>

        </div>

      </div>
    </div>
  );
};

export default Hero;
