import React from "react";
import Hero from "../../components/RequestQuote";
import "./Request.css"
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegramIcon.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail.svg";

import { Link } from "react-router-dom";


const Home: React.FC = () => {
  return (

    <div className="pad">
              <div className="maingridrequest">

        <div className="gridrequest">
        <div>   

          <h2 style={{fontWeight:500,color:'#ffba00'}}>Request A Quote</h2>
          <h2 style={{fontWeight:500,color:'#fff',marginTop:5,fontSize:16,marginBottom:70}}>Feel free to reach out and get a free quote from our expert developers within 24 hours.</h2>


<Link to="https://t.me/lovep_8bit">
<div className="iconsreq">
  <TelegramIcon />
  <p>Request on Telegram</p>
</div>
</Link>
<div style={{marginTop:20}}>
<Link to="mailto:admin@8bitchain.io" >
<div className="iconsreq">
  <MailIcon />
  <p>Request on Email</p>
</div>
</Link>
</div>
</div>          





  <div className="blockrequest">    <Hero />
</div>

</div>

    </div>
    </div>
  );
};

export default Home;
