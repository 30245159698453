import React, { useState } from 'react';
import Maps from '../TransactionHome';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import Charts from "../PairExplorerDetails/validator2";
import Audit from "../../assets/images/audit.png"
import Alert from "../../assets/images/alert.png"
import KYC from "../../assets/images/kyc.png"
import News from "../../assets/images/news.png"

import { ReactComponent as Eth } from '../../assets/images/eth.svg';
import { ReactComponent as Bnb } from '../../assets/images/bnb.svg';
import { ReactComponent as Al } from '../../assets/images/al.svg';
import { ReactComponent as Poly } from '../../assets/images/polygon.svg';
import { ReactComponent as Sol } from '../../assets/images/solana.svg';
import { ReactComponent as Tez } from '../../assets/images/tezos.svg';
import { ReactComponent as Hy } from '../../assets/images/hyperledger.svg';
import { ReactComponent as Fan } from '../../assets/images/main.svg';
import Logo from '../../assets/images/proeight.jpg'




const MainWrapper = styled.div`
margin: 50px 0px;
max-width:100%;
border-radius:20px;
padding:20px;
grid-template-columns:20% 80%;


p{
  text-align:center;
  font-size:17px;
  color:#ffba00;
}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
  @media (max-width: 768px) {
    font-size:22px;
   
   }
}


`;


const Audits = styled.div`
display:grid;
margin-top:20px;
width:100%;
grid-template-columns:1fr 1fr 1fr 1fr;
column-gap:20px;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  row-gap:20px;

}

.auditkyc{
  background-color:black;
  border-radius:20px;
  padding:35px 0;
}


img{
  margin-bottom:20px;
  width:160px;
  height:160px;
}

`;



const AuditDetails = styled.div`
padding:30px;
width:100%;
margin-top:20px;
border-radius:20px;
background-color:black;

p{
  font-size:14px;
}

`;


const Icon = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:20px;
margin-top:20px;

svg{
  width:50px;
  height:50px;
}

img{
  width:50px;
  height:50px;
}


@media (max-width: 768px) {
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  gap:10px;
  svg{
    width:40px;
    height:40px;
  }
  
  img{
    width:40px;
    height:40px;
  }
}
`;


const BlockThree: React.FC = () => {
  
  return (
    <div className="blockthree-wrapper">
       <MainWrapper>
<p>SERVICES</p>
<h1>Discover Our Range of Solutions </h1>
<Audits>
<div className='auditkyc'>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={Audit} />
</div>
  <h1 style={{textAlign:'center',fontSize:28,color:'#ffba00'}}>Security Audits</h1>
</div>

<div className='auditkyc'>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={KYC} />
</div>
  <h1 style={{textAlign:'center',fontSize:28,color:'#ffba00'}}>KYC</h1>
</div>

<div className='auditkyc'>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={Alert} />
</div>
  <h1 style={{textAlign:'center',fontSize:28,color:'#ffba00'}}>Scam Reports</h1>
</div>

<div className='auditkyc'>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><img src={News} />
</div>
  <h1 style={{textAlign:'center',fontSize:28,color:'#ffba00'}}>Crypto News</h1>
</div>


</Audits>
<AuditDetails>
<p>Smart contract auditing helps identify potential loopholes, bugs, and security flaws to protect users and their assets. It involves code review, vulnerability assessment, and risk mitigation to ensure reliability and trustworthiness in Web3 contracts.

</p>
<h4 style={{textAlign:'center',fontSize:16,fontWeight:500,color:'#fff',marginTop:20,marginBottom:5}}>Blockchains We Audit</h4>
<Icon>
<Eth />
<Bnb />
<img src={Logo} />
<Sol />
<Fan />
<Poly />

</Icon>
<div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:30}}>
<a href="./audit" style={{padding:'7px 30px',borderRadius:'8px',backgroundColor:'rgba(256,187,0,0.1)',color:'#ffba00',border:'1px solid #ffba00'}}>View Our Audits</a>

</div>
</AuditDetails>

       </MainWrapper>
    </div>
  );
};

export default BlockThree;
