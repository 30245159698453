import React, { useEffect, useState } from "react";
import { useDarkMode } from "usehooks-ts";
import Logo from "../../assets/images/sidebarLogo.png";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as MenuIcon2 } from "../../assets/icons/menu2.svg";

import { ReactComponent as MoonIcon } from "../../assets/icons/moon.svg";
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Live } from "../../assets/icons/live.svg";
import { ReactComponent as Pair } from "../../assets/icons/liveexplorer.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Link, NavLink, useLocation } from "react-router-dom";
import Menu from "../../assets/images/menu.png";
import BitChain from "../../assets/images/LogoImg.png";
import "./Navigation.scss";
import Dropdown from "../Dropdown";
import Loogo from "../../assets/images/sidebarLogo.png"
import A from "../../assets/images/LogoImg.png"
import B from "../../assets/images/protracker.png"
import C from "../../assets/images/probridge.png"
import D from "../../assets/images/prodex.png"
import E from "../../assets/images/prostake.png"
import F from "../../assets/images/progallery.png"
import G from "../../assets/images/propad.png"
import H from "../../assets/images/propos.png"
import styled from 'styled-components';


const Button = styled.button`
  background-color: #0a090256;
  color: #ffba00;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
  transform: translateY(0px); /* Reset the translateY */
  transition: transform 0.2s, box-shadow 0.2s; /* Add smooth transition effects */
  
  &:hover {
    transform: translateY(-2px); /* Move the button up slightly on hover */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Increase the box shadow on hover for a more pronounced 3D effect */
  }
`;



const Navigation: React.FC = () => {
  const { isDarkMode, toggle } = useDarkMode();
  const [openClose, setOpenClose] = useState(false);
  const [openCloses, setOpenCloses] = useState(false);

  const [activeLink, setActiveLink] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const location = useLocation();

  // Get the current location using useLocation hook
  
  const [isOpen, setIsOpen] = useState(false);

  
  useEffect(() => {
    // Get the current URL pathname
    const currentUrlPathname = window.location.pathname;

    // Set the active link based on the current URL pathname
    setActiveLink(currentUrlPathname);
  }, []);
  
  
  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  useEffect(() => {
    if (isDarkMode) {
      if (document.body.classList.contains("light")) {
        document.body.classList.remove("light");
      }
      document.body.classList.add("dark");
    } else {
      if (document.body.classList.contains("dark")) {
        document.body.classList.remove("dark");
      }
      document.body.classList.add("light");
    }
  }, [isDarkMode]);

  return (
    <div className="navigation-wrapper">
      <div className="mx">
      <div className='desktophead'>
        <div>            <img src={Loogo} className="logoo"/>
</div>
<div> <nav className="site-navigation">
          <ul className="nav">
      <li>
      <Link
            to="/"
            className={`navlink ${location.pathname === '/' ? 'active' : ''}`}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/audit"
            className={`navlink ${
              location.pathname === '/audit' ? 'active' : ''
            }`}
          >
            Audit List
          </Link>
      </li>
      <li>
          <Link
            to="/kyc"
            className={`navlink ${
              location.pathname === '/kyc' ? 'active' : ''
            }`}
          >
            KYC List
          </Link>
      </li>
      <li>
          <Link
            to="/wallofshame"
            className={`navlink ${
              location.pathname === '/wallofshame' ? 'active' : ''
            }`}
          >
            Wall of Shame
          </Link>
      </li>
      <li>
          <Link
            to="/newsroom"
            className={`navlink ${
              location.pathname === '/newsroom' ? 'active' : ''
            }`}
          >
            NewsRoom
          </Link>
      </li>
      <li>
      <Link to="./request">
        <Button>Request Quote</Button>
      </Link>

      </li>

      
            <li>
            <div className="parent-container">
      <div className="changeddrop">
        <div className="changedrop" onClick={() => setOpenDropdown((d) => !d)}>
          <span>
            <img src={Menu} alt="Menu Icon" />
          </span>
        </div>
        {openDropdown && (
          <div className="changedrop-content">
            <div>
              <span><a href="https://8bitchain.finance">Website</a></span>
            </div>
            <div>
              <span><a href="https://protrackers.finance">ProTrackers</a></span>
            </div>
           
            <div>
              <span><a href="https://prodex.finance">ProDex</a></span>
            </div>
         
            <div>
              <span><a href="https://progallery.finance">ProGallery</a></span>
            </div>
             <div>
              <span><a href="https://propad.finance">ProPad</a></span>
            </div>
            <div>
              <span><a href="https://probridge.finance">ProBridge</a></span>
            </div>
            <div>
              <span><a href="https://dapp.8bitchain.finance">Dapp</a></span>
            </div>
            <div>
              <span><a href="https://8Bitscan.com">8Bit Scan</a></span>
            </div>
           
          </div>
        )}
      </div>
    </div>
      </li>
    </ul>
  </nav></div>
      </div>

      <div className="mobilehead">
        <div> <div
              className="circlebg newmwnu"
              onClick={() => setOpenCloses((m) => !m)}
            >
              <MenuIcon2 />
            </div></div>
            <div> <img src={Loogo} className="logoo"/></div>
            <div>  <div
              className="circlebg newmwnu"
              onClick={() => setOpenClose((m) => !m)}
            >
              <MenuIcon />
            </div></div>
      </div>
      

        <AnimatePresence>
          {openClose && (
            <motion.div
              className="sidebar-backdrop"
              onClick={() => setOpenClose(false)}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <motion.div
                className="bar"
                onClick={(e: any) => e.stopPropagation()}
                animate={{ right: 0, transitionDelay: "-200ms" }}
                exit={{ right: -300 }}
                initial={{ right: -300 }}
              >
                <div className="header-side-bar">
                  <div
                    className="close-icon"
                    onClick={() => setOpenClose(false)}
                  >
                    <Close />
                  </div>
                
                  <div style={{ flex: 1 }}>
                    <nav>
                      <NavLink
                        to="/"
                        className="list flex-item"
                        onClick={() => setOpenClose(false)}
                      >
                        <span>Dashboard</span>
                      </NavLink>
                      <NavLink
                        to="/audit"
                        className="list flex-item"
                        onClick={() => setOpenClose(false)}
                      >
                        <span>Audit List</span>
                      </NavLink>
                      <NavLink
                        to="/kyc"
                        className="list flex-item"
                        onClick={() => setOpenClose(false)}
                      >
                        <span>KYC List</span>
                      </NavLink>
                      <NavLink
                        to="/wallofshame"
                        className="list flex-item"
                        onClick={() => setOpenClose(false)}
                      >
                        <span>Wall of Shame</span>
                      </NavLink>
                      <NavLink
                        to="/newsroom"
                        className="list flex-item"
                        onClick={() => setOpenClose(false)}
                      >
                        <span>NewsRoom</span>
                      </NavLink>
                      <NavLink
                        to="/request"
                        className="list flex-item"
                        onClick={() => setOpenClose(false)}
                      >
                        <span>Request Quote</span>
                      </NavLink>
                     
                    </nav>
                    <div className="allChains">
           
          </div>
                  </div>
                 
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {openCloses && (
            <motion.div
              className="sidebar-backdrop"
              onClick={() => setOpenCloses(false)}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <motion.div
                className="bar"
                onClick={(e: any) => e.stopPropagation()}
                animate={{ left: 0, transitionDelay: "-200ms" }}
                exit={{ left: -300 }}
                initial={{ left: -300 }}
              >
                <div className="header-side-bar">
                  <div
                    className="close-icon"
                    onClick={() => setOpenCloses(false)}
                  >
                    <Close />
                  </div>
               
                  <div style={{ flex: 1 }}>
                    <nav>
                      <NavLink
                        to="https://8bitchain.finance"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >
            <img src={A} alt="prodex" width="25" height="25"></img>
                        <span>Website</span>
                      </NavLink>
                      <NavLink
                        to="https://protrackers.finance"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >            <img src={B} alt="prodex" width="25" height="25"></img>

                        <span>ProTrackers</span>
                      </NavLink>
                      <NavLink
                        to="https://probridge.finance"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >            <img src={C} alt="prodex" width="30" height="30"></img>

                        <span>ProBridge</span>
                      </NavLink>
                      <NavLink
                        to="https://prodex.finance"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >            <img src={D} alt="prodex" width="30" height="30"></img>

                        <span>ProDex</span>
                      </NavLink>
                      <NavLink
                        to="https://proassure.finance"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >            <img src={E} alt="prodex" width="30" height="30"></img>

                        <span>ProStake</span>
                      </NavLink>
                      <NavLink
                        to="https://progallery.finance"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >            <img src={F} alt="prodex" width="30" height="30"></img>

                        <span>ProGallery</span>
                      </NavLink>
                      <NavLink
                        to="https://propad.finance"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >            <img src={G} alt="prodex" width="30" height="30"></img>

                        <span>ProPad</span>
                      </NavLink>
                      <NavLink
                        to="https://8bitscan.com"
                        className="list flex-item"
                        onClick={() => setOpenCloses(false)}
                      >            <img src={H} alt="prodex" width="30" height="30"></img>

                        <span>8Bit Scan</span>
                      </NavLink>
                    </nav>
                    <div className="allChains">
           
          </div>
                  </div>
                 
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Navigation;
