import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';

// Styled Components
const Section = styled.section`
  padding: 20px 0;
`;

const MainGrid = styled.div`
  width: 90%;
  display: grid;
  gap: 20px; /* Adjust the gap as needed */
padding:0 100px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* Single column for smaller screens */
    padding:0 10px;

  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three columns in grid view */
  gap: 40px; /* Adjust the gap as needed */
margin-bottom:30px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* Single column for smaller screens */
  }
`;

const GridItem = styled.div`
  background: #0c1318;
  width:100%;
  max-width:100%;

  border-radius: 20px;
  border:1px solid #3a4956;
  display: flex;
  flex-direction: column;
  align-items: center;

  hr{
    width:100%;
    margin-bottom:5px;
    border-bottom:1px solid #3a4956;
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  margin-top:10px;
  height: 50px;
`;

const ProjectName = styled.div`
  margin-top: 5px;
  text-align:center;
  font-size:17px;
  padding:15px;
  color:#fff;
  font-weight: bold;
`;

const Status = styled.div`
  margin-top: 10px;
`;

const Button = styled.button`
  background-color: rgba(255, 244, 140, 0.25);
  color: #ffba00;
  border: 1px solid #ffba00;
  padding: 8px 60px;
  border-radius: 5px;
  margin-bottom:20px;
  margin-top:40px;
  cursor: pointer;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const PageNumber = styled.div`
  font-size: 14px;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
`;

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDARUzWnCkGlBFA4xrxY7K7ELo6isfJzUg",
  authDomain: "proassure-f7e82.firebaseapp.com",
  databaseURL: "https://proassure-f7e82-default-rtdb.firebaseio.com",
  projectId: "proassure-f7e82",
  storageBucket: "proassure-f7e82.appspot.com",
  messagingSenderId: "234072300680",
  appId: "1:234072300680:web:937cf000c5fb6bb460533d",
  measurementId: "G-2Z4R57225G"};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const userDataRef = ref(database, 'news');

const ITEMS_PER_PAGE = 3;
const RECENT_KYC_COUNT = 3; // Number of recent KYC records to display

const AuditedProjects: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userData, setUserData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onValue(userDataRef, (snapshot) => {
      try {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const filteredData = data ? Object.values(data) : [];
  const newdatafilter = filteredData.reverse()
          const firstThreeRecords = newdatafilter.slice(0, 3);
  
          setUserData(firstThreeRecords.reverse());
  
          setLoading(false);
        } else {
          // Handle the case when there is no data in the "wallofshame" location
          setLoading(false);
          setUserData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        // Handle the error as needed
      }
    });
  }, []);
  

  const totalPages = Math.ceil(userData.length / ITEMS_PER_PAGE);
  const lastItemIndex = currentPage * ITEMS_PER_PAGE;
  const firstItemIndex = lastItemIndex - ITEMS_PER_PAGE;
  const displayedUserData = userData.slice(firstItemIndex, lastItemIndex);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const createGridItem = (user: any) => (
    <GridItem key={user.userId}>
    <Status>{user.status}</Status>
<hr/>
<div style={{fontSize:17,marginTop:10,fontWeight:600,textAlign:'center',color:'#ffba00'}} dangerouslySetInnerHTML={{ __html: user.title }} />

<div style={{display:'flex',justifyContent:'flex-end',height:'100%'}}>
      <Link to={`/newsdetail?name=${encodeURIComponent(user.userId)}`}>
        <Button>View News</Button>
      </Link>
      </div>
    </GridItem>
  );

  return (
    <Section>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <MainGrid>
          {loading ? (
            <LoaderContainer>
              <p>Loading...</p>
            </LoaderContainer>
          ) : (
            <Grid>
              {userData.length === 0 ? (
                <GridItem>
                  <div>No Recent News Found</div>
                </GridItem>
              ) : (
                userData.map((user) => createGridItem(user))
              )}
            </Grid>
          )}
        
        </MainGrid>
      </div>
    </Section>
  );
};


export default AuditedProjects;
