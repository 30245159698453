import React, { useState, useEffect ,useRef} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import Copy from "../../assets/icons/copy.png";
import Success from "../../assets/images/success.png";
import QR from "./QrCode"
import Chart from 'chart.js/auto';
import Share from './Share'

const Main = styled.section`
display:flex;
justify-content:center;  
`;


const InnerContent = styled.section`
display:block;
width:90%;

`;


const Block1 = styled.section`
padding:20px;
display:grid;
grid-template-columns:120px auto;
border:1px solid #3a4956;
border-radius:20px;
margin-bottom:30px;

.inner1{
  display:flex;
  justify-content:center;
  align-items:center;

  img{
    margin-left:20px;
    width:70px;
    height:70px;
    @media (max-width: 768px) {
      margin-left:0px;

        width:40px;
        height:40px;
      }
    
    }
  }

@media (max-width: 768px) {
  grid-template-columns:60px auto;
 
}
`;

const ItemBlock = styled.div`
margin-top:20px;
  width:100%;
display:grid;
grid-template-columns:70% auto;
column-gap:20px;
  @media (max-width: 768px) {
    grid-template-columns:1fr;
    row-gap:20px;

    }
`;



const ItemBlock1 = styled.div`
  width:100%;
border-radius:20px;
  @media (max-width: 768px) {
  
    }
`;

const ItemBlock2 = styled.div`
  width:100%;
background-color:black;
border-radius:20px;
padding:20px;
  @media (max-width: 768px) {
  
    }
`;


const ValidatorPage = styled.div`

  width:100%;
  background-color:#000;
  border-radius:20px;
  padding:30px;
  @media (max-width: 768px) {
  
    }
`;



const ValidatorPageInner = styled.div`

  display:flex;
  align-items:center;
  img{
    width:40px;
    height:40px;
    margin-right:10px;
  }
  h2{
    font-weight:500;
    font-size:22px;
    color:#ffba00;
  }


  h3{
    font-weight:500;
    font-size:15px;
  }
  @media (max-width: 768px) {
  
    }
`;


const ValidatorPageInner2 = styled.div`

  display:grid;
  grid-template-columns:1fr 1fr 1fr ;
  column-gap:20px;
  margin-top:20px;

  div{
    background-color:var(--background);
    padding:10px;
    border-radius:10px;
    display:block;
    font-size:12px;

    text-align:center;
    
    h3{
      font-weight:500;
      font-size:16px;
      text-align:center;
    }
  }
 
  @media (max-width: 768px) {
    grid-template-columns:1fr ;
    row-gap:20px;


      div{
    background-color:var(--background);
    padding:10px;
    border-radius:10px;
    display:block;
    font-size:10px;

    text-align:center;
    
    h3{
      font-weight:500;
      font-size:14px;
      text-align:center;
    }
  }
    }
`;


const BlockDetail = styled.div`
background-color:black;
width:100%;
display:block;
border-radius:20px;
height:auto;
padding:30px 50px;


h3{
    font-weight:500;
    font-size:27px;
    margin-top:10px;
    color:#ffba00;
    text-align:center;
  }


img{
  width:100px;
  height:100px;
}

.details{
    margin:10px 0;

    display:grid;
    column-gap:20px;
    grid-template-columns:30% 70%;



    
.progresss {
  margin-top:-30px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 10px;
  height: 2px;
}

.progresss::-webkit-progress-bar {
  background-color: #cecece;
}

.progresss::-webkit-progress-bar,
.progresss::-webkit-progress-value {
  border-radius: 10px;
}

.progresss::-moz-progress-bar {
  border-radius: 10px;
}

.progress1s::-webkit-progress-value {
  background: #ffba00;
}


    h2{
      color:#96a1a8;
        font-size:14px;
        font-weight:500;
        text-align:right;
    }


   

    h3{
        font-size:14px;
        font-weight:500;
    }



}


hr{
    width:95%;
    border: none; /* Remove the default border */
    border-top: 2px solid var(--background);    margin:10px 0;
}
@media (max-width: 768px) {
  background-color:black;
  width:100%;
  display:block;
  border-radius:20px;
  height:auto;
  padding:20px 20px;
  
.details{
  margin:10px 0;

  display:grid;
  column-gap:20px;
  grid-template-columns:1fr;



  
.progresss {
margin-top:-30px;
-webkit-appearance: none;
appearance: none;
border: none;
border-radius: 10px;
height: 2px;
}

.progresss::-webkit-progress-bar {
background-color: #cecece;
}

.progresss::-webkit-progress-bar,
.progresss::-webkit-progress-value {
border-radius: 10px;
}

.progresss::-moz-progress-bar {
border-radius: 10px;
}

.progress1s::-webkit-progress-value {
background: #ffba00;
}


  h2{
    color:#96a1a8;
      font-size:11px;
      font-weight:500;
      text-align:left;
  }


 

  h3{
    max-width:100%;
      font-size:11px;
      font-weight:500;
  }


  

}
hr{
    width:100%;
    border: none; /* Remove the default border */
    border-top: 2px solid var(--background);    margin:0px 0;
}

  }
`;



const TopHead = styled.div`
    display:grid;
    width:100%;
    margin-bottom:50px;
  


    h2{
        font-weight:500;
        font-size:22px;
    }

    h3{
        font-weight:500;
        font-size:17px;
    }

    @media (max-width: 768px) {
      h2{
        font-weight:500;
        font-size:16px;
    }

    h3{
        font-weight:500;
        font-size:12px;
    }

  }

    `;


const AuditDetailComponent: React.FC = () => {
  const [userData, setUserData] = useState<any>({}); // Change 'any' to a more appropriate type
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isCopied, setIsCopied] = useState(false);


  const handleCopy = () => {
    const textField = document.createElement('textarea');
    textField.innerText = userData.contract;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
  
    setIsCopied(true);
  
    // Reset the copied state after a few seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  useEffect(() => {
    // Your Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyDARUzWnCkGlBFA4xrxY7K7ELo6isfJzUg",
      authDomain: "proassure-f7e82.firebaseapp.com",
      databaseURL: "https://proassure-f7e82-default-rtdb.firebaseio.com",
      projectId: "proassure-f7e82",
      storageBucket: "proassure-f7e82.appspot.com",
      messagingSenderId: "234072300680",
      appId: "1:234072300680:web:937cf000c5fb6bb460533d",
      measurementId: "G-2Z4R57225G"  };

        const app = initializeApp(firebaseConfig);
        const database = getDatabase(app);
    
        const name = new URLSearchParams(window.location.search).get('name');
    
        if (name) {
          const userDataRef = ref(database, `kyc/${name}`);
          onValue(userDataRef, (snapshot) => {
            const fetchedData = snapshot.val();
            if (fetchedData) {
              setUserData(fetchedData);
            }
            setIsLoading(false); // Data fetched, loading is done
          });
        }
        
        // Simulate loading for 2 seconds
        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 3000);
      
          // Clean up the timeout when the component unmounts or when data is fetched
          return () => {
            clearTimeout(loadingTimeout);
            if (!isLoading) {
              clearTimeout(loadingTimeout);
            }
          };
        }, []);

       
        // Create a ref for the chart canvas
      

      

  return (
    <div className="main-content-wrapper">
        {isLoading ? ( <div style={{display:'flex',justifyContent:'center',alignItems:"center",fontSize:14,fontWeight:'500',margin:'300px 0'}}>Loading....</div>
         ) : (
            <>
      {/* ...other sections and components */}
      <Main>
        <InnerContent>
       

        

     



<ItemBlock>
<ItemBlock1>


<BlockDetail>
<TopHead>
    <div><h2 style={{textAlign:'center'}}>KYC VERIFICATION</h2></div>
        <div><h2 style={{textAlign:'center',color:'green',fontSize:28,fontWeight:600,marginTop:30}}>KYC Verified !</h2></div>

</TopHead>
<div style={{display:'flex',justifyContent:'center'}}><img src={userData.logoPicture} alt="Logo Picture" width="150px" height="150px"/>
</div>
<h3>{userData.name}</h3>

<p style={{textAlign:'center',fontSize:17,color:'white',marginTop:40,marginBottom:50}}>
This is to verify that <span style={{color:'#ffba00'}}>{userData.mem} Member</span> of the project {userData.name} has successfully completed the
verification process at ProAssure .
</p>






     </BlockDetail>
     
     </ItemBlock1>
<ItemBlock2>
<div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
<div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
<h3 style={{fontWeight:'500',fontSize:'16px',marginRight:'4px'}}>KYC Issued :
                </h3>
              <div style={{display:'flex',alignItems:'center',fontSize:'14px',marginLeft:'4px',justifyContent:'center',color:'#ffba00',borderRadius:'6px',border:'solid 1px #ffba00',backgroundColor:'rgba(256,187,0,0.1)',padding:'3px 6px'}}>{userData.status}</div>

               
            </div>
                </div>

<p style={{textAlign:'center',fontSize:17,color:'white',marginTop:25}}>Project Website</p>
<p style={{textAlign:'center',fontSize:22,color:'white'}} ><a href={userData.website} style={{textAlign:'center',fontSize:18,color:'#ffba00',marginTop:5}}>{userData.website}</a></p>



              
               

<div style={{display:'grid',gridTemplateColumns:'1fr',marginTop:20,marginBottom:40}}>
<a style={{display:'flex',width:'100%',alignItems:'center',fontSize:'14px',marginLeft:'4px',justifyContent:'center',color:'#ffba00',borderRadius:'6px',border:'solid 1px #ffba00',backgroundColor:'rgba(256,187,0,0.1)',padding:'6px 6px'}} href={userData.pdf}>Download Certificate</a>

</div>


<div style={{width:'100%',overflow:'hidden'}}>
<Share />
</div>

</ItemBlock2>

</ItemBlock>




        </InnerContent>
      </Main>
      
      </>
      )}
   </div>
  );
};

export default AuditDetailComponent;
