import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Wa from "../../assets/icons/wa.png";
import Tg from "../../assets/icons/tg.png";
import Tw from "../../assets/icons/tw.png";
import Ig from "../../assets/icons/ig.png";
import Ln from "../../assets/icons/ln.png";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegramIcon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/icons/youtube.svg";

// import { ReactComponent as AppStoreIcon } from "../../assets/icons/appstore.svg";
// import { ReactComponent as TikTok } from "../../assets/icons/tiktok.svg";
// import { ReactComponent as GooglePayIcon } from "../../assets/icons/googleplay.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/mediumIcon.svg";

const Container = styled.section`
  display: grid;
  place-items: center;
`;

const SecContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
`;

const ShareBoxContainer = styled.div`
  --clr-primary: #03a9f4;
  --clr-accent: #060606;
  --clr-icon: #878787;
  --clr-border: #ebedf9;
  --clr-light: var(--background);
  --clr-dark: #111111;
  --transition: all 0.2s ease;

  --clr-facebook: #1877f2;
  --clr-twitter: #46c1f6;
  --clr-instagram: #e1306c;
  --clr-whatsapp: #25d366;
  --clr-linkedin: #0072b1;

  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  background-color: var(--clr-light);
`;

const ShareBoxHeader = styled.header`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #fff;
`;

const HeaderTitle = styled.h2`
  font-size: 1.4rem;
  color: #fff;
`;

const ShareBoxContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
`;

const ShareBoxText = styled.p`
  font-size: 1rem;
  color: #fff;
`;

const SocialLinks = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 10px;
`;

const SocialLink = styled.a<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 43px;
  width: 43px;
  border-radius: 4px;
  text-decoration: none;
  border: 1px solid #fff;
  transition: var(--transition);
  color: ${props => props.isActive ? 'var(--clr-primary)' : 'var(--clr-icon)'};

  &:hover {
    border-color: ${props => props.isActive ? 'var(--clr-primary)' : 'var(--clr-border)'};
  }
`;

const ShareInputContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background:#fff;
  padding: 0 6px;
  border-radius: 4px;
  border: 2px solid #fff;
  transition: var(--transition);
  border-color: ${props => props.isActive ? 'var(--clr-primary)' : 'var(--clr-border)'};
`;

const InputIcon = styled.i<{ isActive: boolean }>`
  width: 20px;
  color: ${props => props.isActive ? 'var(--clr-primary)' : 'var(--clr-icon)'};
  transition: var(--transition);
`;

const InputField = styled.input<{ isActive: boolean }>`
  flex: 1;
  padding: 0 10px;
  height: 50px;
  border: none;
  outline: none;
  font-size: 1rem;
  max-width: calc(100% - 80px);
  color: var(--clr-accent);
  transition: var(--transition);
  color: ${props => props.isActive ? 'var(--clr-primary)' : 'var(--clr-accent)'};
`;

const CopyButton = styled.button`
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 1rem;
  cursor: pointer;
  width: 70px;
  font-weight: 700;
  border: 2px solid #ffba00;
  color: #ffba00;
  background: rgba(256,187,0,0.2);
  transition: var(--transition);

  &:hover {
    background: #ffba00;
    color: #fff;
  }
`;



const ShareBox: React.FC = () => {
    const [activeLink, setActiveLink] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState(window.location.href);
    const location = useLocation(); // Use useLocation hook within the component
  
    const getCurrentPath = () => {
      return location.pathname;
    };
  
    const handleCopyClick = () => {
      const textToCopy = activeLink === "input" ? getCurrentPath() : inputValue;
      const tempInput = document.createElement("input");
      tempInput.value = textToCopy;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
  
      setInputValue("Copied");
  
      setTimeout(() => {
        setInputValue(activeLink === "input" ? getCurrentPath() : window.location.href);
        setActiveLink(null);
      }, 3000);
    };


    const handleTelegramClick = () => {
    const text = "Check Consise Scam Report Prepared by ProAssure: \n" + window.location.href + "\n\nIn order to Request Audit, Please Contact @Lovep_8bit";
    const encodedText = encodeURIComponent(text);
    const telegramURL = `https://t.me/share/url?url=${encodedText}`;
    window.open(telegramURL, "_blank");
  };


  const handleWhatsAppClick = () => {
    const text = `Check Consise Scam Report Prepared by ProAssure:\n${window.location.href}\n\nIn order to Request Audit, Please Contact @Lovep_8bit`;
    const encodedText = encodeURIComponent(text);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodedText}`;
    window.open(whatsappURL, "_blank");
  };


  const handleTwitterClick = () => {
  const text = `Check Consise Scam Report Prepared by ProAssure: ${window.location.href} - In order to Request Audit, Please Contact @Lovep_8bit`;
  const encodedText = encodeURIComponent(text);
  const twitterURL = `https://twitter.com/intent/tweet?text=${encodedText}`;
  window.open(twitterURL, "_blank");
};

const handleFacebookClick = () => {
    const text = `Check Consise Scam Report Prepared by ProAssure: ${window.location.href} - In order to Request Audit, Please Contact @Lovep_8bit`;
    const encodedText = encodeURIComponent(text);
    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${encodedText}`;
    window.open(facebookURL, "_blank");
  };


const handleLinkedInClick = () => {
    const text = `Check Consise Scam Report Prepared by ProAssure: ${window.location.href} - In order to Request Audit, Please Contact @Lovep_8bit`;
    const encodedText = encodeURIComponent(text);
    const linkedInURL = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${encodedText}`;
    window.open(linkedInURL, "_blank");
  };


  
    return (
      <Container>
        <SecContainer>
          <ShareBoxContainer>
            <ShareBoxHeader>
              <HeaderTitle>Social Share</HeaderTitle>
            </ShareBoxHeader>
            <ShareBoxContent>
              <ShareBoxText>Share Scam Report via</ShareBoxText>
              <SocialLinks>
              <SocialLink
                href="#"
                data-attr="telegram"
                isActive={activeLink === "telegram"}
                onClick={() => handleTelegramClick()}
              >
<img src={Tg} width={30}></img>     
                </SocialLink>
               <SocialLink
  href="#"
  data-attr="whatsapp"
  isActive={activeLink === "whatsapp"}
  onClick={() => handleWhatsAppClick()}
>
<img src={Wa} width={30}></img>     
           </SocialLink>
           <SocialLink
  href="#"
  data-attr="twitter"
  isActive={activeLink === "twitter"}
  onClick={() => handleTwitterClick()}
>
<img src={Tw} width={30}></img>     
                </SocialLink>
               <SocialLink
  href="#"
  data-attr="facebook"
  isActive={activeLink === "facebook"}
  onClick={() => handleFacebookClick()}
>
<img src={Ig} width={30}></img>     
                </SocialLink>
               <SocialLink
  href="#"
  data-attr="linkedin"
  isActive={activeLink === "linkedin"}
  onClick={() => handleLinkedInClick()}
>
<img src={Ln} width={30}></img>     
                </SocialLink>
              </SocialLinks>
              <ShareInputContainer isActive={activeLink === "input"}>
              <InputField
  type="text"
  value={activeLink === "input" ? getCurrentPath() : window.location.href}
  id="inputField"
  isActive={activeLink === "input"}
  readOnly
/>







                <CopyButton id="copyBtn" onClick={handleCopyClick}>
                  Copy
                </CopyButton>
              </ShareInputContainer>
            </ShareBoxContent>
          </ShareBoxContainer>
        </SecContainer>
      </Container>
    );
  };
  
  export default ShareBox;
  
  
  
  
  
  