import React, { useState, useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import styled, { css } from 'styled-components';
import { Link, NavLink, useLocation } from "react-router-dom";
import ProfileImg from "../../assets/images/LogoImg.png";
import Copy from "../../assets/icons/copy.png";
import TabbedComponents from "./TabbedComponents"

import { ReactComponent as DownArrowIcon } from "../../assets/icons/down-arrow.svg";
import { ReactComponent as DrodpownDownArrowIcon } from "../../assets/icons/dropdown-down-arrow.svg";





const Mobnavo = styled.div`
display: none;

@media (max-width: 768px) {
  display: flex;
  overflow-x: auto;
margin-bottom:20px;
  gap:20px;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--search-icon-bg-clr);
    border-radius: 22px;
  }

}
.radialProgressBars {
  border-radius: 50%;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -19px;
  background: #ddd;
  margin-left:-5px;
  float:left;
}
.radialProgressBars .overlays {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin: auto;
  background-color: var(--background);

  text-align: center;
}

.progress-80s {
  background-image: -webkit-linear-gradient(left, #ffba00 50%, transparent 50%), -webkit-linear-gradient(36deg, #ffba00 50%, #ffd970 50%);
  background-image: linear-gradient(90deg, #ffba00 50%, transparent 50%), linear-gradient(54deg, #ffba00 50%, #ffd970 50%);
}

.itempart2s{
  display: flex;
  width: 30px;
  margin-top:10px;
  background-color: var(--background);
  height: 30px;
  justify-content:ceenter;
  align-items:center;
  border-radius: 50%;
  padding-right: 5px;
  margin-top: auto;
  margin-left: -10px;
  margin-bottom: auto;
}
`;


const Ite = styled.div`
display: none;

@media (max-width: 768px) {
  display: grid;
  min-width: 140px;
  height: 55px;
  background-color: black;
  border-radius: 40px;
  margin: 0 10px 0 0px;
  grid-template-columns: 80% 20%;  }
  .para{
    font-size:9px;
  }
`;

const Ites = styled.div`
display: none;

@media (max-width: 768px) {
    display: grid;
    min-width: 140px;
  
    height: 55px;
    background-color: black;
    border-radius: 70px;
    margin: 0 0 0 0px;
    grid-template-columns: 10% 90%;
  }
  .para{
    font-size:9px;
  }
`;


const BlockDetail = styled.div`
background-color:black;
width:100%;
display:block;
border-radius:20px;
height:auto;
padding:20px 40px;

.details{
    margin:10px 0;

    display:grid;
    column-gap:20px;
    grid-template-columns:25% 75%;



    
.progresss {
  margin-top:-30px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 10px;
  height: 2px;
}

.progresss::-webkit-progress-bar {
  background-color: #cecece;
}

.progresss::-webkit-progress-bar,
.progresss::-webkit-progress-value {
  border-radius: 10px;
}

.progresss::-moz-progress-bar {
  border-radius: 10px;
}

.progress1s::-webkit-progress-value {
  background: #ffba00;
}


    h2{
      color:#96a1a8;
        font-size:14px;
        font-weight:500;
        text-align:right;
    }


   

    h3{
        font-size:14px;
        font-weight:500;
    }



}


hr{
    width:95%;
    border: none; /* Remove the default border */
    border-top: 2px solid var(--background);    margin:10px 0;
}
@media (max-width: 768px) {
  background-color:black;
  width:100%;
  display:block;
  border-radius:20px;
  height:auto;
  padding:20px 20px;
  
.details{
  margin:10px 0;

  display:grid;
  column-gap:20px;
  grid-template-columns:1fr;



  
.progresss {
margin-top:-30px;
-webkit-appearance: none;
appearance: none;
border: none;
border-radius: 10px;
height: 2px;
}

.progresss::-webkit-progress-bar {
background-color: #cecece;
}

.progresss::-webkit-progress-bar,
.progresss::-webkit-progress-value {
border-radius: 10px;
}

.progresss::-moz-progress-bar {
border-radius: 10px;
}

.progress1s::-webkit-progress-value {
background: #ffba00;
}


  h2{
    color:#96a1a8;
      font-size:11px;
      font-weight:500;
      text-align:left;
  }


 

  h3{
    max-width:100%;
      font-size:11px;
      font-weight:500;
  }


  

}
hr{
    width:100%;
    border: none; /* Remove the default border */
    border-top: 2px solid var(--background);    margin:0px 0;
}

  }
`;



const MainWrapper = styled.div`
margin: 50px 0px;
max-width:100%;
border-radius:15px;
padding:15px;
grid-template-columns:15% 80%;


p{
  text-align:center;
  font-size:15px;
  color:#ffba00;
}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
  @media (max-width: 768px) {
    font-size:22px;
   
   }
}
`;

const TopHead = styled.div`
    display:grid;
    width:100%;
    margin-bottom:50px;
    grid-template-columns: 100%;
  


    h2{
        font-weight:500;
        font-size:22px;
    }

    h3{
        font-weight:500;
        font-size:17px;
    }

    @media (max-width: 768px) {
      h2{
        font-weight:500;
        font-size:16px;
    }

    h3{
        font-weight:500;
        font-size:12px;
    }

  }

    `;

const BlockDetails: React.FC = ({

}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const address = "0x9FC4b18Cc357A23c3dfb3eD2C4DAb0F197F3bB88"; 

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="pairslist-header">
     
       
     <MainWrapper>
<p>RECENTS

</p>
<h1>Our Recently Completed Projects and News</h1>






      

    <TabbedComponents />



    </MainWrapper>



    </div>
  );
};

export default BlockDetails;
