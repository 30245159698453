import React, { useState } from 'react';
import styled from 'styled-components';
import InternalTransaction from './InternalTransaction';
import Logs from './Logs';
import RawTrace from './RawTrace';
import StateChanges from './StateChanges';

const TabbedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color:black;
  margin-top:20px;
  border-radius:20px;
  @media (max-width: 768px) {
padding:20px;
  }
  `;

const TabHeader = styled.div`
  display: flex;
  margin:20px;
  width:100%;
  color:#fff;
padding:10px;
justify-content:center;
align-items:center;
border-radius:10px;
@media (max-width: 768px) {
  width:100%;
  margin:0px;
  background-color:transparent;

  overflow-x: auto !important;
  white-space: nowrap !important;
  overflow-y: hidden !important;
  &::-webkit-scrollbar {
    height:2px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--search-icon-bg-clr);
    border-radius: 22px;
  }
}
`;



const TabHeadernew = styled.div`
  width:600px;
   @media (max-width: 768px) {
    width:auto;
    margin-left:120px;

  }
 
`;
const TabButton = styled.button<{ isActive: boolean }>`
  flex: 1;
  padding: 10px;
  margin:0 5px;
  width:140px;
  border-radius:5px;
  border:none;
  background-color: ${props => (props.isActive ? 'transparent' : 'transparent')};
  border-bottom: ${props => (props.isActive ? '1px solid #ffba00' : 'transparent')}; 
  color: ${props => (props.isActive ? '#ffba00' : '#fff')};
  cursor: pointer;
  @media (max-width: 768px) {
      background-color: ${props => (props.isActive ? 'var(--background)' : 'transparent')};
      border: ${props => (props.isActive ? 'none' : '1px solid var(--background)')};
      width:auto;

  }
`;

const TabContent = styled.div`
  padding: 0px;
  @media (max-width: 768px) {
    padding: 0px;
    padding-top:20px;

  }
`;

const tabNames = [
  'Audit',
  'KYC',
  'Scam Reports',
  'Crypto News',
];

const tabComponents = [
  <InternalTransaction />,
  <Logs />,
  <RawTrace />,
  <StateChanges />,
];

const TabbedComponent: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TabbedContainer>
      <TabHeader>
      <TabHeadernew>
        {tabNames.map((name, index) => (
          <TabButton
            key={index}
            isActive={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            {name}
          </TabButton>
        ))}
        </TabHeadernew>
      </TabHeader>
      <TabContent>{tabComponents[activeTab]}</TabContent>
    </TabbedContainer>
  );
};

export default TabbedComponent;
