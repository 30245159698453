import React, { useState, useEffect ,useRef} from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { Link } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import styled from 'styled-components';
import Copy from "../../assets/icons/copy.png";
import Success from "../../assets/images/success.png";
import QR from "./QrCode"
import Chart from 'chart.js/auto';
import Share from './Share'
import ScamPostForm from './ScamPostForm'; // Import the ScamPostForm component
import "./NewsDetails.scss";

const Main = styled.section`
display:flex;
justify-content:center;  
`;


const InnerContent = styled.section`
display:block;
width:90%;

`;


const Block1 = styled.section`
padding:20px;
display:grid;
grid-template-columns:120px auto;
border:1px solid #3a4956;
border-radius:20px;
margin-bottom:30px;

.inner1{
  display:flex;
  justify-content:center;
  align-items:center;

  img{
    margin-left:20px;
    width:70px;
    height:70px;
    @media (max-width: 768px) {
      margin-left:0px;

        width:40px;
        height:40px;
      }
    
    }
  }

@media (max-width: 768px) {
  grid-template-columns:60px auto;
 
}
`;

const ItemBlock = styled.div`
margin-top:20px;
  width:100%;
display:grid;
grid-template-columns:70% auto;
column-gap:20px;
  @media (max-width: 768px) {
    grid-template-columns:1fr;
    row-gap:20px;

    }
`;



const ItemBlock1 = styled.div`
  width:100%;
border-radius:20px;

  @media (max-width: 768px) {
  
    }
`;

const ItemBlock2 = styled.div`

height:fit-content;
  width:100%;
background-color:black;
border-radius:20px;
padding:20px;
  @media (max-width: 768px) {
  
    }
`;


const ValidatorPage = styled.div`

  width:100%;
  background-color:#000;
  border-radius:20px;
  padding:30px;
  @media (max-width: 768px) {
  
    }
`;



const ValidatorPageInner = styled.div`

  display:flex;
  align-items:center;
  img{
    width:40px;
    height:40px;
    margin-right:10px;
  }
  h2{
    font-weight:500;
    font-size:22px;
    color:#ffba00;
  }


  h3{
    font-weight:500;
    font-size:15px;
  }
  @media (max-width: 768px) {
  
    }
`;


const ValidatorPageInner2 = styled.div`

  display:grid;
  grid-template-columns:1fr 1fr 1fr ;
  column-gap:20px;
  margin-top:20px;

  div{
    background-color:var(--background);
    padding:10px;
    border-radius:10px;
    display:block;
    font-size:12px;

    text-align:center;
    
    h3{
      font-weight:500;
      font-size:16px;
      text-align:center;
    }
  }
 
  @media (max-width: 768px) {
    grid-template-columns:1fr ;
    row-gap:20px;


      div{
    background-color:var(--background);
    padding:10px;
    border-radius:10px;
    display:block;
    font-size:10px;

    text-align:center;
    
    h3{
      font-weight:500;
      font-size:14px;
      text-align:center;
    }
  }
    }
`;


const BlockDetail = styled.div`
background-color:black;
width:100%;
display:block;
border-radius:20px;
height:auto;
padding:30px 50px;


img{
  width:100%;
  border-radius:10px;
}
.details{
    margin:10px 0;

    display:grid;
    column-gap:20px;
    grid-template-columns:30% 70%;



    
.progresss {
  margin-top:-30px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 10px;
  height: 2px;
}

.progresss::-webkit-progress-bar {
  background-color: #cecece;
}

.progresss::-webkit-progress-bar,
.progresss::-webkit-progress-value {
  border-radius: 10px;
}

.progresss::-moz-progress-bar {
  border-radius: 10px;
}

.progress1s::-webkit-progress-value {
  background: #ffba00;
}


    h2{
      color:#96a1a8;
        font-size:14px;
        font-weight:500;
        text-align:right;
    }


   

    h3{
        font-size:14px;
        font-weight:500;
    }



}


hr{
    width:95%;
    border: none; /* Remove the default border */
    border-top: 2px solid var(--background);    margin:10px 0;
}
@media (max-width: 768px) {
  background-color:black;
  width:100%;
  display:block;
  border-radius:20px;
  height:auto;
  padding:20px 20px;
  
.details{
  margin:10px 0;

  display:grid;
  column-gap:20px;
  grid-template-columns:1fr;



  
.progresss {
margin-top:-30px;
-webkit-appearance: none;
appearance: none;
border: none;
border-radius: 10px;
height: 2px;
}

.progresss::-webkit-progress-bar {
background-color: #cecece;
}

.progresss::-webkit-progress-bar,
.progresss::-webkit-progress-value {
border-radius: 10px;
}

.progresss::-moz-progress-bar {
border-radius: 10px;
}

.progress1s::-webkit-progress-value {
background: #ffba00;
}


  h2{
    color:#96a1a8;
      font-size:11px;
      font-weight:500;
      text-align:left;
  }


 

  h3{
    max-width:100%;
      font-size:11px;
      font-weight:500;
  }


  

}
hr{
    width:100%;
    border: none; /* Remove the default border */
    border-top: 2px solid var(--background);    margin:0px 0;
}

  }
`;



const TopHead = styled.div`
    display:grid;
    width:100%;
    text-align:center;
    margin-bottom:50px;
  


    h2{
      text-align:center;
color:red;
        font-weight:600;
        font-size:35px;
    }

    h3{
        font-weight:500;
        font-size:17px;
    }

    @media (max-width: 768px) {
      h2{
        font-weight:500;
        font-size:16px;
    }

    h3{
        font-weight:500;
        font-size:12px;
    }

  }

    `;


const AuditDetailComponent: React.FC = () => {
  const [userData, setUserData] = useState<any>({}); // Change 'any' to a more appropriate type
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isCopied, setIsCopied] = useState(false);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [showScamForm, setShowScamForm] = useState(false);

  const handlePostScam = () => {
    setShowScamForm(true);
    document.body.classList.add('body-no-scroll');
      document.documentElement.classList.add('html-no-scroll');
          document.querySelector('.main-content-wrapper')?.classList.add('blur-content');



  };

  const handleCloseScamForm = () => {
    setShowScamForm(false);
    document.body.classList.remove('body-no-scroll');
      document.documentElement.classList.remove('html-no-scroll');
      document.querySelector('.main-content-wrapper')?.classList.remove('blur-content');


  };

  useEffect(() => {
    // Debugging: Output the value of showScamForm to the console
    console.log('showScamForm:', showScamForm);
  }, [showScamForm]);

 
  const handleImageClick = () => {
    setShowImageViewer(true);
  };

  const handleCopy = () => {
    const textField = document.createElement('textarea');
    textField.innerText = userData.contract;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
  
    setIsCopied(true);
  
    // Reset the copied state after a few seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  useEffect(() => {
    // Your Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyDARUzWnCkGlBFA4xrxY7K7ELo6isfJzUg",
      authDomain: "proassure-f7e82.firebaseapp.com",
      databaseURL: "https://proassure-f7e82-default-rtdb.firebaseio.com",
      projectId: "proassure-f7e82",
      storageBucket: "proassure-f7e82.appspot.com",
      messagingSenderId: "234072300680",
      appId: "1:234072300680:web:937cf000c5fb6bb460533d",
      measurementId: "G-2Z4R57225G"  };

        const app = initializeApp(firebaseConfig);
        const database = getDatabase(app);
    
        const name = new URLSearchParams(window.location.search).get('name');
    
        if (name) {
          const userDataRef = ref(database, `news/${name}`);
          onValue(userDataRef, (snapshot) => {
            const fetchedData = snapshot.val();
            if (fetchedData) {
          
              setUserData(fetchedData);
            }
            setIsLoading(false); // Data fetched, loading is done
          });
        }
        
        // Simulate loading for 2 seconds
        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 3000);
      
          // Clean up the timeout when the component unmounts or when data is fetched
          return () => {
            clearTimeout(loadingTimeout);
            if (!isLoading) {
              clearTimeout(loadingTimeout);
            }
          };
        }, []);

        const high = userData.high;
        const medium = userData.med;
        const low = userData.low;
        const informational = userData.informational;
      
        // Create a ref for the chart canvas
      
        const chartRef = useRef<HTMLCanvasElement | null>(null);
        const chartInstanceRef = useRef<Chart | null>(null);
      
        useEffect(() => {
          // Destroy the previous Chart instance if it exists
          if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
          }
      
          // Check if all values are zero
          if (high === 0 && medium === 0 && low === 0 && informational === 0) {
            // Display a message when all values are zero
            if (chartRef.current) {
              const canvas = chartRef.current;
              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.font = '14px Arial #fff';
                ctx.fillText('No issue in project', canvas.width / 2 - 70, canvas.height / 2);
              }
            }
          } else {
            // Create the new donut chart when there are non-zero values
            if (chartRef.current) {
              const donutChart = new Chart(chartRef.current, {
                type: 'doughnut',
                data: {
                  labels: ['High', 'Medium', 'Low', 'Informational'],
                  datasets: [
                    {
                      data: [high, medium, low, informational],
                      backgroundColor: ['#FF5733', '#FFC300', '#33FF57', '#57A7FF'], // You can customize the colors
                    },
                  ],
                },
                options: {
                  responsive: false, // Disable responsiveness
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false, // Hide the legend
                    },
                  },
                  layout: {
                    padding: {
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                    },
                  },
                },
              });
      
              // Store the chart instance for later destruction
            }
          }
        }, [high, medium, low, informational]);
      
        useEffect(() => {
          // Customize the chart width and height after the chart has been initialized
          if (chartRef.current) {
            const parentNode = chartRef.current.parentNode as HTMLElement;
            parentNode.style.width = '50px';
            parentNode.style.height = '50px';
          }
        }, []);
      

  return (
    <div className={`main-content-wrapper ${showScamForm ? 'blur-content' : ''}`}>
        {isLoading ? ( <div style={{display:'flex',justifyContent:'center',alignItems:"center",fontSize:14,fontWeight:'500',margin:'300px 0'}}>Loading....</div>
         ) : (
            <>
      {/* ...other sections and components */}
      <Main>
        <InnerContent>
       

        
<ValidatorPage>
<ValidatorPageInner>
{userData.logoPicture && (
  <img src={userData.logoPicture} alt="Logo Picture" />
)}
<div><h2>{userData.name}</h2>
<h3>
<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
  {userData.contract && (
    <>
      {isMobile ? (
        <>
          {userData.contract.slice(0, 10)}
          {"..."}
          {userData.contract.slice(-10)}
        </>
      ) : (
        userData.contract
      )}
      {isCopied ? (
        <img
          src={Success}
          alt="Copied"
          style={{ marginLeft: '5px', width: '12px', height: '12px' }}
        />
      ) : (
        <img
          src={Copy}
          alt="Copy"
          style={{ marginLeft: '5px', width: '12px', height: '12px', cursor: 'pointer' }}
          onClick={handleCopy}
        />
      )}
    </>
  )}
</div>
</h3></div>

</ValidatorPageInner>





</ValidatorPage>
     



<ItemBlock>
<ItemBlock1>


<BlockDetail>


<div style={{fontSize:30,fontWeight:600,textAlign:'center',color:'#ffba00'}} dangerouslySetInnerHTML={{ __html: userData.title }} />
<img
          src={userData.evidence}
          style={{ marginTop: 20, marginBottom: 20, cursor: 'pointer' }}
          onClick={() => handleImageClick()}
        /><div dangerouslySetInnerHTML={{ __html: userData.desc }} />
<div style={{fontSize:22,marginTop:50,fontWeight:500,color:'#ffba00'}}>Conclusion :-</div>
<div dangerouslySetInnerHTML={{ __html: userData.conclusion }} />



     </BlockDetail>
   
     </ItemBlock1>
     <ItemBlock2>
     <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
     <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
     <h3 style={{fontWeight:'500',fontSize:'16px',marginRight:'4px'}}>Article Date :
                     </h3>
                   <div style={{display:'flex',alignItems:'center',fontSize:'14px',marginLeft:'4px',justifyContent:'center',color:'#ffba00',borderRadius:'6px',border:'solid 1px #ffba00',backgroundColor:'rgba(256,187,0,0.1)',padding:'3px 6px'}}>{userData.status}</div>
     
                    
                 </div>
                     </div>
     
     <p style={{textAlign:'center',fontSize:17,color:'white',marginTop:25}}>Author</p>
     <p style={{textAlign:'center',fontSize:22,color:'#ffba00'}} >{userData.author}</p>
     <p style={{fontSize:17,color:'white',marginTop:45,marginBottom:5}}>Are you aware of any Recent Update ?</p>

     <button className="custom-button-request" style={{width:'100%'}} onClick={handlePostScam}>
        Post News
      </button>

      {showScamForm && (
        <ScamPostForm onClose={handleCloseScamForm} />
      )}
     
     
                   
                    
     
    
     
     
     <div style={{width:'100%',overflow:'hidden',marginTop:50}}>
     <Share />
     </div>
     
     </ItemBlock2>
     

</ItemBlock>




        </InnerContent>
      </Main>
      
      </>
      )}
   </div>
  );
};

export default AuditDetailComponent;
